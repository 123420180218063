<style lang="scss" scoped>
</style>
<template>
  <el-dialog title="新增屬性" v-model="state.dialogFormVisible" width="800px">
    <el-form
      :model="state.form"
      :ref="(el) => (state.addFrom = el)"
      :rules="state.addRules"
    >
      <div class="flex_line_c">
        <el-row class="width_100">
          <el-col :span="24">
            <el-form-item
              label="名稱"
              :label-width="state.formLabelWidth"
              prop="name"
              class="width_100"
            >
              <el-input
                v-model="state.form.name"
                autocomplete="off"
                placeholder="請輸入屬性名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="門店"
              :label-width="state.formLabelWidth"
              prop="classify"
              class="width_100"
            >
              <el-input
                v-model="state.form.classify"
                autocomplete="off"
                placeholder="請輸入門店名稱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="庫存" prop="inventory">
              <el-input-number
                :min="0"
                v-model="state.form.inventory"
                placeholder="請輸入庫存"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="狀態"
              prop="region"
              class="width_100"
              :label-width="state.formLabelWidth"
            >
              <el-select placeholder="請選擇" v-model="state.form.region">
                <el-option label="正常" value="1"></el-option>
                <el-option label="禁用" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="add_submit">確 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { reactive, watch, getCurrentInstance } from "vue";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    toggleDialogVisible(data) {
      return data;
    },
    reloadData(data) {
      return data;
    },
  },
  setup(props, { emit }) {
    // 变量

    // 表单验证

    // state
    const state = reactive({
      allSorts: [], // 門店數據
      radio: "1", // 選擇分類單選
      dialogFormVisible: props.dialogVisible,
      form: props.form,
      addFrom: "", // 表单元素
      formLabelWidth: "52px", // label的宽度
      addRules: {
        // 表单正则
        name: [{ required: true, message: "請輸入屬性名稱", trigger: "blur" }],
        region: [{ required: true, message: "請選擇狀態", trigger: "blur" }],
        classify: [
          { required: true, message: "請輸入門店名稱", trigger: "blur" },
        ],
        inventory: [
          {
            required: true,
            message: "請輸入庫存",
            trigger: "blur",
          },
        ],
      },
    });

    // 监听
    watch(
      () => props.dialogVisible,
      (val, old) => {
        if (val) state.dialogFormVisible = val;
      }
    );

    watch(
      () => state.dialogFormVisible,
      (val, old) => {
        if (!val) emit("toggleDialogVisible", true);
      }
    );

    watch(
      () => props.form,
      (val, old) => {
        state.form = val;
      }
    );

    // 生命周期
    // created
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $message, $http } = proxy;

    // methods

    const add_submit = async () => {
      // 点击确定按钮
      state.addFrom.validate((valid) => {
        if (!valid) return;
        emit("reloadData", state.form);
        state.dialogFormVisible = false;
      });
    };

    return { state, add_submit, props };
  },
};
</script>
